<template>
  <div class="echarts-container"></div>
</template>

<script>
import * as echarts from 'echarts/core';
import { TooltipComponent } from 'echarts/components';
import { TreeChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([TooltipComponent, TreeChart, CanvasRenderer]);

export default {
  name: 'TreeChart',
  props: {
    treeData: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.initChart();
    this.$watch('treeData', this.updateChart, {deep: true});
    // 监听树图节点的点击事件
    this.chart.on('click', (e) => {
      console.log('e:', e);
      this.$emit('clickNode', e.data);
    });
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el);
      this.updateChart();
    },
    updateChart() {
      if (this.chart) {
        const option = {
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
            enterable: true,
            confine: true,
            formatter: (params) => {
              return params.marker + params.name + '<br/>' + '$ ' + (params.value || '--');
            },
            backgroundColor: '#FFF',
            borderColor: '#1890FF',
            borderWidth: 1,
            borderRadius: 8,
            padding: [6, 8],
            textStyle: {
              color: '#333',
              fontWeight: 400,
              fontSize: 16,
              lineHeight: 20,
              width: 60,
              overflow: 'breakAll',
              ellipsis: '...'
            },
            extraCssText: 'box-shadow: 0 0 9px rgba(0, 0, 0, 0.3);text-align: right;'
          },
          series: [
            {
              type: 'tree',
              data: [this.treeData],
              name: '树图',
              top: '1%',
              left: '7%',
              bottom: '1%',
              right: '20%',
              layout: 'orthogonal',
              orient: 'LR',
              edgeShape: 'curve',
              roam: false,
              initialTreeDepth: -1, // 展开所有节点
              symbolSize: 16,
              itemStyle: {
                color: '#1890FF',
                borderColor: 'rgba(255, 144, 0, 1)',
                borderWidth: 1,
                borderType: 'dotted',
                borderCap: 'square',
                shadowColor: 'rgba(0,121,221,0.3)',
                shadowBlur: 16,
                opacity: 1
              },
              label: {
                show: true,
                distance: 8,
                position: 'left',
                verticalAlign: 'middle',
                align: 'center',
                fontSize: 16,
                color: '#333',
                backgroundColor: '#F0F5FA',
                borderColor: '#1890FF',
                borderWidth: 1,
                borderType: 'solid',
                borderRadius: 4,
                padding: [6, 12],
                shadowColor: 'rgba(0,121,221,0.3)',
                shadowBlur: 6,
                width: 80,
                overflow: 'truncate',
                ellipsis: '...'
              },
              lineStyle: {
                color: 'rgba(0,0,0,.35)',
                width: 2,
                curveness: 0.5,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 10
              },
              emphasis: {
                disabled: false,
                focus: 'self',
                blurScope: 'coordinateSystem',
                itemStyle: {
                  color: '#1890FF',
                  borderWidth: 1,
                  borderType: 'solid',
                  borderCap: 'square',
                  shadowColor: 'rgba(0,121,221,0.3)',
                  shadowBlur: 12,
                  opacity: 1
                },
                lineStyle: {
                  color: 'rgba(0,0,0,.45)',
                  width: 2,
                  curveness: 0.5,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                  shadowBlur: 6
                },
                label: {
                  color: '#333',
                  fontWeight: 600
                }
              },
              blur: {},
              leaves: {
                label: {
                  distance: 8,
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left',
                  formatter: function (params) {
                    // 提供默认值处理，避免 undefined
                    const index = params.data.index !== undefined ? params.data.index : '-';
                    return `${index} ${params.name}`;
                  }
                }
              },
              animation: true,
              expandAndCollapse: true,
              animationDuration: 550,
              animationEasing: 'linear',
              animationDelay: 0,
              animationDurationUpdate: 750,
              animationEasingUpdate: 'cubicInOut',
              animationDelayUpdate: 0
            }
          ]
        };
        this.chart.setOption(option);
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
</script>

<style scoped>
.echarts-container {
  width: 100%;
  height: 100%; /* 确保有足够的高度 */
}
</style>
